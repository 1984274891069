module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr"],"defaultLanguage":"fr","siteUrl":"https://www.pixilearn.fr","trailingSlash":"always","i18nextOptions":{"supportedLngs":["en","fr"],"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:slug","getLanguageFromPath":true},{"matchPath":"/:lang?/","getLanguageFromPath":true},{"matchPath":"/:lang?/solution","getLanguageFromPath":true},{"matchPath":"/:lang?/services","getLanguageFromPath":true},{"matchPath":"/:lang?/contact","getLanguageFromPath":true},{"matchPath":"/:lang?/blog","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-30,"duration":100},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
